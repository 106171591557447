import { SdbRegistration } from "@custom-types/project-data-management-types";
import { RegistrationState } from "@faro-lotv/service-wires";
import { runtimeConfig } from "@src/runtime-config";
import { APITypes } from "@stellar/api-logic";
import { isSdbBackgroundTaskOngoing } from "@utils/sdb-background-tasks-utils";

/**
 * @returns Whether the SdbRegistration entity is still preparing or has already been registered
 *
 * @param registration SdbRegistration revision entity
 */
export function isPreparedRegistrationRevision(
  registration: SdbRegistration
): boolean {
  return (
    isRegistrationRevisionPreparing(registration) ||
    isRegisteredRegistrationRevision(registration)
  );
}

/**
 * @returns true iff the SdbRegistration entity is still preparing
 * Early exit with false if the task property is defined and is not an ongoing task.
 *
 * @param registration SdbRegistration revision entity
 */
export function isRegistrationRevisionPreparing(
  registration: SdbRegistration
): boolean {
  if (registration.task && !isSdbBackgroundTaskOngoing(registration.task)) {
    return false;
  }

  return (
    registration.state === RegistrationState.cloudRegistrationStarted ||
    registration.state === RegistrationState.started
  );
}

/**
 * @returns true iff the registration revision entity has already been registered
 *
 * @param registrationRevision Registration revision entity
 */
export function isRegisteredRegistrationRevision(
  registration: SdbRegistration
): boolean {
  return registration.state === RegistrationState.registered;
}

/**
 * Constructs a URL for publishing a registration revision for a specified project.
 *
 * @param {APITypes.ProjectId} projectId - The ID of the project for which the registration is being published.
 * @param {string} revisionId - The ID of the registration revision to be published.
 * @returns {string} A URL object pointing to the endpoint for publishing the specified registration revision.
 */
export function getInspectAndPublishToolUrl(
  projectId: APITypes.ProjectId,
  revisionId: string
): string {
  const url = new URL(
    `/data-preparation/project/${projectId}`,
    runtimeConfig.urls.sphereViewerUrl
  );

  url.searchParams.append("revisionId", revisionId);

  return url.href;
}
